<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" :md="headToHeadExpanded ? 12 : 6">
                <h4>Head to Head</h4>
                <stats-card v-if="showAverage" @secondTitleActive="showAverage = false" title="Pie" secondTitle="Scatter" @expand="toggleHeadToHeadExpanded" :showOptionChevron='true' :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeams"
                                    v-model="headToHeadAway"
                                    @change="updateHeadToHeadResults"
                                    label="Team 1"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadPieChartTeamOption"
                                    v-model="headToHeadHome"
                                    @change="updateHeadToHeadResults"
                                    label="Team 2"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showHeadToHeadFilters = !showHeadToHeadFilters"
                                    >
                                    {{ showHeadToHeadFilters ? 'Hide Filters' : 'Show Filters' }}
                                    <v-icon class="ml-1">{{ showHeadToHeadFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showHeadToHeadFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadHomeAwayOptions"
                                        v-model="headToHeadFilter"
                                        @change="updateHeadToHeadFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Venue"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFilters"
                                        v-model="headToHeadYearFromFilter"
                                        @change="headToHeadYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFilters"
                                        v-model="headToHeadYearToFilter"
                                        @change="headToHeadYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        </transition>
                </template>
                    <template slot="chart">
                        <div id="head-to-pie-chart">
                            <apexchart
                                type="pie"
                                height="650"
                                :options="headToHeadPieChartOptions"
                                :series="headToHeadPieChartTotals"
                            />
                        </div>
                    </template>
                </stats-card>
                <stats-card v-else @titleActive="showAverage = true" title="Pie" secondTitle="Scatter"  @expand="toggleHeadToHeadExpanded" :showOptionChevron="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeams"
                                    v-model="headToHeadAway"
                                    @change="updateHeadToHeadResults"
                                    label="Team 1"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadPieChartTeamOption"
                                    v-model="headToHeadHome"
                                    @change="updateHeadToHeadResults"
                                    label="Team 2"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showHeadToHeadFilters = !showHeadToHeadFilters"
                                    >
                                    {{ showHeadToHeadFilters ? 'Hide Filters' : 'Show Filters' }}
                                    <v-icon class="ml-1">{{ showHeadToHeadFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showHeadToHeadFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadHomeAwayOptions"
                                        v-model="headToHeadFilter"
                                        @change="updateHeadToHeadFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Venue"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFilters"
                                        v-model="headToHeadYearFromFilter"
                                        @change="headToHeadYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFilters"
                                        v-model="headToHeadYearToFilter"
                                        @change="updateHeadToHeadYearToFilter"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </transition>
                </template>
                    <template slot="chart">
                        <div id="head-to-head-scatter-chart">
                            <apexchart
                                type="scatter"
                                :options="headToHeadScatterChartOptions"
                                :series="headToHeadScatterSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
            <v-col cols="12" :md="headToHeadExpanded ? 12 : 6">
                <h4 :style="this.$vuetify.breakpoint.xs ? 'margin-top: 20px' : ''">Results</h4>
                <stats-card v-if="showOverall" @secondTitleActive="showOverall = false" title="Table" secondTitle="Scatter" @expand="toggleHeadToHeadExpanded" :showOptionChevron='true' :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeams"
                                    v-model="overallTeam"
                                    @change="updateOverallResults"
                                    label="Team"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :disabled="!overallTeam"
                                    :items="overallOppositionOption"
                                    v-model="overallOpposition"
                                    @change="updateOverallFilter"
                                    multiple
                                    label="Opposition"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showOverallFilters = !showOverallFilters"
                                    >
                                    {{ showOverallFilters ? 'Hide Filters' : 'Show Filters' }}
                                    <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showOverallFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallHomeAwayOptions"
                                        @change="overallHomeFilterChanged"
                                        v-model="overallHomeFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Venue"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFilters"
                                        v-model="overallYearFromFilter"
                                        @change="overallFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFilters"
                                        v-model="overallYearToFilter"
                                        @change="overallToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        </transition>
                </template>
                    <template slot="chart">
                        <div id="overall-table">
                            <v-data-table
                                :headers="overallTableHeaders"
                                :items="getFilteredOverallResults"
                                class="elevation-1"
                                mobile-breakpoint="0">

                                <template v-slot:item.result="{item}">
                                    {{ calculateResultText(item) }}
                                </template>

                                <template v-slot:item.opposition="{item}">
                                    {{ item.homeTeam.id == overallTeam.id ? item.awayTeam.name : item.homeTeam.name }}
                                </template>

                                <template v-slot:item.winningMarginPoints="{item}">
                                    {{ calculateResultText(item) == 'Lost' ? '-' + item.winningMarginPoints : item.winningMarginPoints }}
                                </template>

                            </v-data-table>
                        </div>
                    </template>
                </stats-card>
                <stats-card v-else @titleActive="showOverall = true" title="Table" secondTitle="Scatter"  @expand="toggleHeadToHeadExpanded" :showOptionChevron="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeams"
                                    v-model="overallTeam"
                                    @change="updateOverallResults"
                                    label="Team"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :disabled="!overallTeam"
                                    :items="overallOppositionOption"
                                    v-model="overallOpposition"
                                    @change="updateOverallFilter"
                                    multiple
                                    label="Opposition"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showOverallFilters = !showOverallFilters"
                                    >
                                    {{ showOverallFilters ? 'Hide Filters' : 'Show Filters' }}
                                    <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showOverallFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallHomeAwayOptions"
                                        @change="overallHomeFilterChanged"
                                        v-model="overallHomeFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Venue"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFilters"
                                        v-model="overallYearFromFilter"
                                        @change="overallFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFilters"
                                        v-model="overallYearToFilter"
                                        @change="overallToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        </transition>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart">
                            <apexchart
                                type="scatter"
                                :options="overallScatterChartOptions"
                                :series="overallScatterSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
            <!-- <v-col cols="12" :md="gameweekExpanded ? 12 : 6">
                <stats-card v-if="showAverage" @secondTitleActive="showAverage = false" title="Weekly" secondTitle="Cumulative" @expand="toggleGameweekExpanded" :showOptionChevron='true'>
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <v-combobox
                                    :items="playerLeagues"
                                    v-model="gameweekPlayers"
                                    label="Players"
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    hide-no-data
                                    deletable-chips
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <div class="text-h6" style="text-wrap: nowrap;">Gameweeks Range</div>
                                <v-range-slider
                                    v-model="gameweekRange"
                                    ticks
                                    dense
                                    :tick-labels="gameweeks"
                                    min="1"
                                    max="8"
                                ></v-range-slider>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart">
                            <apexchart
                                type="scatter"
                                :options="gameweekScatterChartOptions"
                                :series="gameweekScatterSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
                <stats-card v-else @titleActive="showAverage = true" title="Weekly" secondTitle="Cumulative"  @expand="toggleGameweekExpanded" :showOptionChevron="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <v-combobox
                                    :items="playerLeagues"
                                    v-model="gameweekPlayers"
                                    label="Players"
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    hide-no-data
                                    deletable-chips
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <div class="text-h6" style="text-wrap: nowrap;">Gameweeks Range</div>
                                <v-range-slider
                                    v-model="gameweekRange"
                                    ticks
                                    dense
                                    :tick-labels="gameweeks"
                                    min="1"
                                    max="8"
                                ></v-range-slider>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart">
                            <apexchart
                                type="line"
                                :options="cumulativeChartOptions"
                                :series="cumulativeSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
            <v-col cols="12" :md="positionExpanded ? 12 : 6">
                <stats-card v-if="showPosition" @secondTitleActive="showPosition = false" title="Weekly" secondTitle="Trend" @expand="togglePositionExpanded" :showOptionChevron='true'>
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <v-combobox
                                    :items="playerLeagues"
                                    v-model="positionPlayers"
                                    label="Players"
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    hide-no-data
                                    deletable-chips
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <div class="text-h6" style="text-wrap: nowrap;">Gameweeks Range</div>
                                <v-range-slider
                                    v-model="positionRange"
                                    ticks
                                    dense
                                    :tick-labels="gameweeks"
                                    min="1"
                                    max="8"
                                ></v-range-slider>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart">
                            <apexchart
                                type="line"
                                :options="positionChartOptions"
                                :series="positionSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
                <stats-card v-else @titleActive="showPosition = true" title="Weekly" secondTitle="Trend"  @expand="togglePositionExpanded" :showOptionChevron="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <v-combobox
                                    :items="playerLeagues"
                                    v-model="positionPlayers"
                                    label="Players"
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    hide-no-data
                                    deletable-chips
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '6' : '12'">
                            <div>
                                <div class="text-h6" style="text-wrap: nowrap;">Gameweeks Range</div>
                                <v-range-slider
                                    v-model="positionRange"
                                    ticks
                                    dense
                                    :tick-labels="gameweeks"
                                    min="1"
                                    max="8"
                                ></v-range-slider>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart">
                            <apexchart
                                type="line"
                                :options="gameweekChartOptions"
                                :series="gameweekSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col> -->
        </v-row>
    </div>
  </template>
  
  <script>
  import StatsCard from '@/components/card/statsCard/StatsCard'
import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Individual',
    components: {
        StatsCard
    },
    data() {
      return {
        loading: false,
        showAverage: true,
        showOverall: true,
        showPosition: true,
        showHeadToHeadFilters: false,
        showOverallFilters: false,
        gameweekPlayers: [],
        gameweekExpanded: false,
        headToHeadExpanded: false,
        headToHeadHome: null,
        headToHeadAway: null,
        headToHeadFilter: {"label": 'All', "value": null},
        headToHeadYearFromFilter: 'All',
        headToHeadYearToFilter: 'All',
        overallTeam: null,
        overallOpposition: [],
        overallYearFromFilter: 'All',
        overallYearToFilter: 'All',
        overallHomeFilter: null,
        overallTableHeaders: [
          {
            text: 'Opposition',
            sortable: false,
            value: 'opposition',
          },
          { text: 'Venue', value: 'venue', sortable: false },
          { text: 'Result', value: 'result', sortable: false },

          { text: 'Margin', value: 'winningMarginPoints', sortable: false },
          {
            text: 'Matchday',
            sortable: false,
            value: 'stage',
          },
          { text: 'Date', value: 'year', sortable: false }
        ],
        gameweekRange: [1, 8],
        gameweekChartOptions: {
          chart: {
            id: 'progress-chart',
            zoom: {
                enabled: false  // Disabling zoom functionality
            }
          },
          title: {
            text: 'Position',
            align: 'left',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#666'
            }
          },
          xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7', '8'],
            title: {
                text: 'Game Week'
            },
            max: 10       
          },
          yaxis: {
            title: {
                text: 'Points'
            }
          }
        },
        cumulativeChartOptions: {
          chart: {
            id: 'progress-chart',
            zoom: {
                enabled: false  // Disabling zoom functionality
            }
          },
          title: {
            text: 'Points',
            align: 'left',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#666'
            }
          },
          xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7', '8', ''],
            title: {
                text: 'Game Week'
            },
            max: 10      
          },
          yaxis: {
            title: {
                text: 'Points'
            }
          }
        },
        positionPlayers: [],
        positionExpanded: false,
        positionRange: [1, 8],
        positionChartOptions: {
          chart: {
            id: 'progress-chart',
            zoom: {
                enabled: false
            }
          },
          title: {
            text: 'Position',
            align: 'left',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#666'
            }
          },
          xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7', '8'],
            title: {
                text: 'Game Week'
            },
            max: 10         
          },
          yaxis: {
            title: {
                text: 'Points'
            },
            min: 1,
            reversed: true
          }
        },
        playerLeagues: ['Keano', 'Trish', 'Sir Dave'],
        gameweeks: [1, 2, 3, 4, 5, 6, 7, 8]
      }
    },
    computed: {
        ...mapGetters(["getTeams", "getResults", "getResultTotals", "getFilteredResults", "getOverallResults", "getFilteredOverallResults"]),
        headToHeadPieChartOptions() { 
            return {
                title: {
                    text: 'Win Percentage',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                dataLabels: {
                    enabled: true,
                },
                chart: {
                    type: 'pie',
                },
                colors: this.headToHeadPieChartColours,
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        startAngle: 0,
                    }
                },
                labels: this.headToHeadPieChartLabels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        },
        headToHeadHomeAwayOptions(){
            let teamOptions = [{"label": 'All', "value": null}];
            if(this.headToHeadAway){
                teamOptions.push({"label": this.headToHeadAway.name + ' home', "value": this.headToHeadAway.id});
            }
            if(this.headToHeadHome){
                teamOptions.push({"label": this.headToHeadHome.name + ' home', "value": this.headToHeadHome.id});
            }
           return teamOptions;
        },
        headToHeadPieChartLabels(){
            let labels = ['Team 1', 'Team 2'];
            if(this.headToHeadHome){
                labels[0] = this.headToHeadHome.name;
            }
            if(this.headToHeadAway){
                labels[1] = this.headToHeadAway.name;
            }
            labels.push('Draw');
            return labels;
        },
        headToHeadPieChartColours(){
            let colours = ['#888888', '#949494', "#808080"];
            if(this.headToHeadHome){
                colours[0] = this.headToHeadHome.colour;
            }
            if(this.headToHeadAway){
                colours[1] = this.headToHeadAway.colour;
            }
            return colours;
        },
        headToHeadPieChartTotals(){
            let resultTotals = this.$store.getters['getResultTotals'];
            let charTotals = [1, 1, 0];
            if(this.headToHeadHome && !isNaN(resultTotals[this.headToHeadHome.id]) && this.headToHeadAway && !isNaN(resultTotals[this.headToHeadAway.id])){
               charTotals = [resultTotals[this.headToHeadHome.id], resultTotals[this.headToHeadAway.id], resultTotals['draw']]
            }
            return charTotals;
        },
        headToHeadPieChartTeamOption(){
            if(this.headToHeadAway){
                return this.$store.getters['getTeams'].filter(it => it.id != this.headToHeadAway.id)
            }
            return this.$store.getters['getTeams'];
        },
        headToHeadScatterChartOptions(){
            let colours = [];
            if(this.headToHeadHome && this.headToHeadAway){
                colours = [this.headToHeadHome.colour, this.headToHeadAway.colour, '#808080'];
            }
            return {
                title: {
                    text: 'Winning History',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                chart: {
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    }
                },
                colors: colours,
                xaxis: {
                    title: {
                        text: 'Year'
                    },
                    type: 'numeric',
                    tickAmount: 'dataPoints',
                    labels: {
                        formatter: function(val) {
                            let resp = val;
                            if(val && val.toFixed){
                                resp = val.toFixed(0)
                            }
                            return resp;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Winning Margin',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: '#666'
                        }
                    },
                    min: 0
                }
            }
        },
        headToHeadScatterSeries(){
            let series = [];
            let team1 = { name: this.headToHeadAway ? this.headToHeadAway.name : 'Team 1', data: [] };
            let team2 = { name: this.headToHeadHome ? this.headToHeadHome.name : 'Team 2', data: [] };
            let draw = { name: "Draw", data: [] };
            for(let i=0; i<this.getFilteredResults.length; i++){
                let currentResult = this.getFilteredResults[i];
                if(currentResult.homeTeam.id == this.headToHeadHome.id){
                    if(currentResult.result == "HOME_WIN"){
                        team2.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else if(currentResult.result == "AWAY_WIN"){
                        team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else {
                        draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                    }
                } else {
                    if(currentResult.result == "HOME_WIN"){
                        team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else if(currentResult.result == "AWAY_WIN"){
                        team2.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else {
                        draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                    }
                }
            }
            series.push(team2);
            series.push(team1);
            series.push(draw);
            return series;
        },
        headToHeadYearFilters(){
            let years = ['All'];
            years.push(...this.getResults.map(it => it.year));
            return years;
        },
        overallOppositionOption(){
            let choices = this.$store.getters['getTeams'].map(it => it);
            if(this.overallTeam){
                choices = choices.filter(it => it.id != this.overallTeam.id)
            }
            if(this.overallOpposition && this.overallOpposition.length > 0){
                choices = choices.filter(it => it.id != this.overallOpposition.id)
            }
            return choices;
        },
        overallHomeAwayOptions(){
            let teamOptions = [{"label": 'All', "value": null}];
            if(this.overallTeam){
                teamOptions.push({"label": this.overallTeam.name + ' home', "value": this.overallTeam.id});
                teamOptions.push({"label": this.overallTeam.name + ' away', "value": this.overallTeam.id*-1});
            }
           return teamOptions;
        },
        overallScatterChartOptions(){
            let colours = [];
            if(this.overallTeam){
                colours = [this.overallTeam.colour];
            }
            for(let i=0;i<this.overallOpposition.length;i++){
                colours.push(this.overallOpposition[i].colour);
            }
            colours.push('#808080');
            return {
                title: {
                    text: 'Winning History',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                chart: {
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    }
                },
                colors: colours,
                xaxis: {
                    title: {
                        text: 'Year'
                    },
                    type: 'numeric',
                    tickAmount: this.calculateTicksForOverallScatter(),
                    labels: {
                        formatter: function(val) {
                            let resp = val;
                            if(val && val.toFixed){
                                resp = val.toFixed(0)
                            }
                            return resp;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Winning Margin',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: '#666'
                        }
                    },
                    min: 0
                }
            }
        },
        overallScatterSeries(){
            let series = [];
            let team1 = { name: this.overallTeam ? this.overallTeam.name : 'Team', data: [] };
            let draw = { name: "Draw", data: [] };
            series.push(team1);
            for(let i=0; i<this.overallOpposition.length; i++){
                let opposingTeam = { name: this.overallOpposition[i].name, data: [] };
                for(let i=0; i<this.getFilteredOverallResults.length; i++){
                    let currentResult = this.getFilteredOverallResults[i];
                    if(currentResult.homeTeam.id == this.overallTeam.id){
                        if(currentResult.result == "HOME_WIN"){
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else if(currentResult.result == "AWAY_WIN"){
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                opposingTeam.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else {
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                            }
                        }
                    } else if(currentResult.awayTeam.id == this.overallTeam.id){
                        if(currentResult.result == "HOME_WIN"){
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                opposingTeam.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else if(currentResult.result == "AWAY_WIN"){
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else {
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                            }
                        }
                    }
                }
                series.push(opposingTeam);
            }
            series.push(draw);
            return series;
        },
        overallYearFilters(){
            let years = ['All'];
            years.push(...this.getOverallResults.map(it => it.year));
            return years;
        },
        gameweekSeries(){
            let series = [];
            series.push(            {
                name: 'Player',
                data: [30, 40, 35, 50, 49, 60, 50, 45]
            });
            series.push({
                name: 'Overall Average',
                data: [25, 35, 40, 45, 50, 55, 40, 42]});
            // series.push({name: 'Horse Heads Average',
            //     data: [25, 35, 42, 41, 50, 35, 45, 35]});
            if(this.gameweekPlayers.indexOf('Keano') > -1){
                series.push({name: 'Keano',
                data: [25, 34, 40, 41, 55, 35, 48, 32]})
            }

            // now filter by gameweek
            series = series.map(serie => {
                return {
                    ...serie, // Spread operator to copy other properties of the object
                    data: serie.data.slice(this.gameweekRange[0]-1, this.gameweekRange[1])
                };
            });
            return series;
        },
        cumulativeSeries(){
            let series = [];
            series.push(            {
                name: 'Player',
                data: [30, 70, 100, 150, 199, 259, 329, 389]
            });
            series.push({
                name: 'Overall Average',
                data: [31, 60, 102, 150, 179, 200, 279, 339]});
            // series.push({name: 'Horse Heads Average',
            //     data: [41, 60, 106, 140, 199, 280, 339, 409]});

            if(this.gameweekPlayers.indexOf('Keano') > -1){
                series.push({name: 'Keano',
                data: [41, 61, 101, 130, 209, 275, 339, 401]});
            }

            // now filter by gameweek
            series = series.map(serie => {
                return {
                    ...serie, // Spread operator to copy other properties of the object
                    data: serie.data.slice(this.gameweekRange[0]-1, this.gameweekRange[1])
                };
            });
            return series;
        },
        positionSeries(){
            let series = [];
            series.push(            {
                name: 'Player',
                data: [10, 12, 8, 3, 1, 2, 3, 6]
            });

            if(this.positionPlayers.indexOf('Keano') > -1){
                series.push({name: 'Keano',
                data: [1, 1, 2, 4, 2, 1, 1, 2]});
            }

            // now filter by gameweek
            series = series.map(serie => {
                return {
                    ...serie, // Spread operator to copy other properties of the object
                    data: serie.data.slice(this.positionRange[0]-1, this.positionRange[1])
                };
            });
            return series;
        },
        gameweekScatterSeries(){
            let series = [];
            series.push({
                name: 'Player',
                data: [
                    { x: 1, y: 30 }, // Round 1, 30 Points
                    { x: 2, y: 40 }, // Round 2, 40 Points
                    { x: 3, y: 35 }, // Round 3, 35 Points
                    { x: 4, y: 30 }, // Round 1, 30 Points
                    { x: 5, y: 40 }, // Round 2, 40 Points
                    { x: 6, y: 35 }, // Round 3, 35 Points
                    { x: 7, y: 30 }
                ]
            });
            series.push({
                name: 'Overall Average',
                data: [
                { x: 1, y: 31 }, // Round 1, 30 Points
                { x: 2, y: 38 }, // Round 2, 40 Points
                { x: 3, y: 37 }, // Round 3, 35 Points
                { x: 4, y: 40 }, // Round 1, 30 Points
                { x: 5, y: 45 }, // Round 2, 40 Points
                { x: 6, y: 32 }, // Round 3, 35 Points
                { x: 7, y: 31 }
                ]
            });
            // series.push({
            //     name: 'Horse Heads Average',
            //     data: [
            //     { x: 1, y: 32 }, // Round 1, 30 Points
            //     { x: 2, y: 39 }, // Round 2, 40 Points
            //     { x: 3, y: 36 }, // Round 3, 35 Points
            //     { x: 4, y: 35 }, // Round 1, 30 Points
            //     { x: 5, y: 43 }, // Round 2, 40 Points
            //     { x: 6, y: 37 }, // Round 3, 35 Points
            //     { x: 7, y: 37 }
            //     ]
            // });
            if(this.gameweekPlayers.indexOf('Keano') > -1){
                series.push({
                name: 'Keano',
                data: [
                    { x: 1, y: 35 }, // Round 1, 30 Points
                    { x: 2, y: 41 }, // Round 2, 40 Points
                    { x: 3, y: 39 }, // Round 3, 35 Points
                    { x: 4, y: 39 }, // Round 1, 30 Points
                    { x: 5, y: 47 }, // Round 2, 40 Points
                    { x: 6, y: 41 }, // Round 3, 35 Points
                    { x: 7, y: 38 }
                    ]
                });
            }

                        // now filter by gameweek
            series = series.map(serie => {
                return {
                    ...serie, // Spread operator to copy other properties of the object
                    data: serie.data.slice(this.gameweekRange[0]-1, this.gameweekRange[1])
                };
            });
            return series;
        }
    },
    mounted(){
        this.fetchTeamsByCompetition(2);
    },
    methods: {
        ...mapActions([
            "fetchTeamsByCompetition", "fetchHeadToHeadResults", "updateHeadToHeadHomeFilter", 
            "updateHeadToHeadYearFromFilter", "updateHeadToHeadYearToFilter", "updateOverallFromFilter", 
            "updateOverallToFilter", "fetchOverallResults", 'updateOverallOppositionFilter', 'updateOveralldHomeFilter'
        ]),
        toggleGameweekExpanded(){
            this.loading = true;
            this.gameweekExpanded = !this.gameweekExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        toggleHeadToHeadExpanded(){
            this.loading = true;
            this.headToHeadExpanded = !this.headToHeadExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        togglePositionExpanded(){
            this.loading = true;
            this.positionExpanded = !this.positionExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        updateHeadToHeadResults(){
            if(this.headToHeadHome && this.headToHeadAway){
                this.fetchHeadToHeadResults({"team1Id": this.headToHeadHome.id, "team2Id": this.headToHeadAway.id, "competitionId": 2});
            }
            if(!((this.headToHeadHome && this.headToHeadFilter.value == this.headToHeadHome.id) || (this.headToHeadAway && this.headToHeadFilter.value == this.headToHeadAway.id))){
                this.headToHeadFilter = {"label": 'All', "value": null};
                this.updateHeadToHeadFilter();
            }
        },
        updateHeadToHeadFilter(){
            this.updateHeadToHeadHomeFilter(this.headToHeadFilter.value)
        },
        headToHeadYearFromFilterChanged(){
            this.updateHeadToHeadYearFromFilter(this.headToHeadYearFromFilter);
        },
        headToHeadYearToFilterChanged(){
            this.updateHeadToHeadYearToFilter(this.headToHeadYearToFilter);
        },
        updateOverallResults(){
            if(this.overallTeam){
                this.fetchOverallResults({"teamId": this.overallTeam.id, "competitionId": 2});
            }
        },
        updateOverallFilter(){
            this.updateOverallOppositionFilter(this.overallOpposition);
        },
        overallHomeFilterChanged(){
            this.updateOveralldHomeFilter(this.overallHomeFilter.value);
        },
        overallFromFilterChanged(){
            this.updateOverallFromFilter(this.overallYearFromFilter);
        },
        overallToFilterChanged(){
            this.updateOverallToFilter(this.overallYearToFilter);
        },
        calculateResultText(item){
            if(item.result == 'Draw'){
                return 'Draw'
            } else if(item.result == 'HOME_WIN'){
                return item.homeTeam.id == this.overallTeam.id ? 'Won' : 'Lost'
            } else if(item.result == 'AWAY_WIN'){
                return item.awayTeam.id == this.overallTeam.id ? 'Won' : 'Lost'
            }
        },
        calculateTicksForOverallScatter(){
            return 10;
        }
    }
  }
</script>  

<style scoped>
  .settings_height {
    max-height: 200px;
    overflow: hidden;
    }

    .settings_height_mobile {
    max-height: 240px;
    overflow: hidden;
    }

    .fade-enter-active, .fade-leave-active {
    transition: max-height 0.5s  ease-in;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    max-height: 0;
    }
</style>